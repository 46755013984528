
/**
 *
 * @param {*} d new Date() | 秒
 * @param {Object} opts 格式 YYYY-MM-DD hh:mm:ss 区分大小写
 * @example
 * formatTime(d) => 2018.07.16
 * formatTime(d, 'YYYY-MM-DD hh:mm:ss') => 2018-07-16 17:18:33
 */
const formatTime = (d, opts) => {
  const date = typeof d !== 'object' ? new Date(d * 1000) : d
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  let r = [year, month, day].map(formatNumber).join('.') // 默认值
  if (opts) {
    r = opts.replace(/YYYY|MM|DD|hh|mm|ss/g,
      match => {
        switch (match) {
          case 'YYYY':
            return year
          case 'MM':
            return formatNumber(month)
          case 'DD':
            return formatNumber(day)
          case 'hh':
            return formatNumber(hour)
          case 'mm':
            return formatNumber(minute)
          case 'ss':
            return formatNumber(second)
          default:
            return match
        }
      }
    )
  }
  return r
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

const addDays = (date, number) => {
  date.setDate(date.getDate() + number)
  return date
}

const addMonths = (date, number) => {
  date.setMonth(date.getMonth() + number)
  return date
}

/*
 * @action 增加月份或者天数
 * @params d 日期（秒数或者日期对象）
 * @params number 增加的数值
 * @params type 增加的是日（day）还是 月（month）
 * @params formatType 日期日期格式化的形式 默认 YYYY-MM-D
 * */

export function dateAdd(d, number, type, formatType) {
  const dateType = formatType || 'YYYY-MM-DD'
  let date = typeof d !== 'object' ? new Date(d * 1000) : d

  if (type === 'day') {
    date = addDays(date, number)
  } else if (type === 'month') {
    date = addMonths(date, number)
  }

  return formatTime(date, dateType)
}
/*
 * @action 获取某个月的天数
 * @params date 日期  形式为 YYYY-MM-D
 * */
export function daysInMonth(date) {
  const dateList = date ? date.split('-') : []
  const nowDate = new Date(dateList[0], dateList[1], 0)

  return nowDate.getDate()
}

/*
 * @action 获取某天是周几，，周日为0，
 * @params date 日期  形式为 YYYY-MM-D
 * */
export function dayOfWeek(date) {
  const day = new Date(Date.parse(date.replace(/-/g, '/'))) // 将日期值格式化
  return day.getDay()
}

/**
 * 获取 cookie expire
 * @param day
 * @returns {*}
 */
export function getCookieExpires(day = 365) {
  const d = new Date()
  d.setTime(d.getTime() + (24 * 60 * 60 * 1000 * day))
  return d.toGMTString()
}

export default formatTime
