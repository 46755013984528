import tpl from './photoSwiper.vue'
const plugin = {}

plugin.install = function(Vue) {
  let $vm = null
  const ChildExtend = Vue.extend(tpl)
  if (!$vm) {
    $vm = new ChildExtend({
      el: document.createElement('div'),
    })
    document.body.appendChild($vm.$el)
  }

  Vue.prototype.$preview = function(option) {
    $vm = Object.assign($vm, option, { value: true })
  }
}

export default plugin
