// COMMON
export const PAGE_SCROLL_TOP = 'PAGE_SCROLL_TOP' // 滚动高度
export const SCREEN_WIDTH = 'SCREEN_WIDTH' // 浏览器宽度
export const USERNAME = 'USERNAME' // 用户名
export const LOGIN_DIALOG_VISIBLE = 'LOGIN_DIALOG_VISIBLE' // 登陆弹窗
export const CART_NUM = 'CART_NUM' // 购物车数量
export const TOKEN = 'TOKEN'
export const GET_USER_DATA = 'GET_USER_DATA' // 获取用户信息
export const APP_CONFIG = 'APP_CONFIG' // app配置信息

