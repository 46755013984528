import loadjs from 'loadjs'
import formatTime from '~/assets/lib/dateFormat'

const dateStr = formatTime(new Date(), 'YYYYMMDDhhmm')
loadjs(`//cstaticdun.126.net/load.min.js?t=${dateStr}`, 'external', { numRetries: 2 })

loadjs.ready('external', {
  error: function(depsNotFound) {
    loadjs('//s.wzj.com/js/yundun.js', 'cdn', { numRetries: 2 })
  }
});