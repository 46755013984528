import Vue from 'vue'
import { SCREEN_WIDTH, PAGE_SCROLL_TOP, USERNAME, LOGIN_DIALOG_VISIBLE, TOKEN, CART_NUM, GET_USER_DATA, APP_CONFIG } from './mutation-types'
const cookieparser = process.server ? require('cookieparser') : undefined

export const state = () => ({
  appCityId: '',
  visitorId: '',
  pageScrollTop: '',
  screenWidth: '',
  username: '',
  loginDialogVisible: false,
  token: '',
  cartNum: 0,
  appConfig: {},
  refererPage: '',
})

export const mutations = {
  [SCREEN_WIDTH](state, status) {
    state.screenWidth = status
  },
  [PAGE_SCROLL_TOP](state, status) {
    state.pageScrollTop = status
  },
  [PAGE_SCROLL_TOP](state, status) {
    state.pageScrollTop = status
  },
  [USERNAME](state, status) {
    state.username = status
  },
  [LOGIN_DIALOG_VISIBLE](state, status) {
    state.loginDialogVisible = status
  },
  [TOKEN](state, status) {
    state.token = status
  },
  [CART_NUM](state, status) {
    state.cartNum = status
  },
  [APP_CONFIG](state, status) {
    state.appConfig = status
  },
}

export const actions = {
  async [GET_USER_DATA]({ commit }) {
    async function getName() {
      const res = await Vue.prototype.$http('/proxy/user-center-data')
      if (res) {
        commit('USERNAME', res.results[0].nickname)
        // commit('CART_NUM', res.results[0].shopping_cart_count)
      }
    }
    async function getCartNum() {
      const res = await Vue.prototype.$http('/proxy/count-shopping-cart')
      if (res) {
        const cartNum = res.results[0].count
        commit('CART_NUM', cartNum)
      }
    }
    getName()
    getCartNum()
  },
  nuxtServerInit({ commit }, { req }) {
    let token = ''
    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      token = parsed.token
    }
    commit(TOKEN, token)
  },
}
