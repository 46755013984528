import { fromMobile, fromRobot, getIEVersion } from '~/assets/lib/tool'

const H5_MALL_HOST = 'https://m.wzj.com'

export default async function(context) {
  const { route, redirect, req, error } = context
  // UA 获取
  context.userAgent = process.server ? req.headers['user-agent'] : navigator.userAgent

  if (fromMobile(context.userAgent)) { // 移动模式 PC商城重定向到H5商城
    if (route.path !== '/app-links' && !fromRobot(context.userAgent)) {
      const host = process.server ? req.headers.host : H5_MALL_HOST
      const redirectHost = host.indexOf('wzj') > -1 ? host.replace(/www/, 'm') : H5_MALL_HOST // 开发环境跳转 defaultHost
      return redirect(`${redirectHost}${route.fullPath}`)
    }
  } else { // PC 模式下 PC商城路由设置
    if (route.path === '/app') {
      return redirect(`${H5_MALL_HOST}/app`) // 重定向到m站进行APP下载链接获取
    } else if (route.path === '/home') { // H5商城Home Tab页对应PC商城首页
      return redirect('/')
    }
  }
  // IE升级提示
  const version = getIEVersion(context.userAgent)
  if (version && version < 11) {
    redirect('/upgradeHint')
  }
  // 404路由兜底
  if (!route.name || route.name === 'null') {
    return error({ statusCode: 404, message: 'Not found' })
  }
}
