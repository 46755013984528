import buildURL from 'axios/lib/helpers/buildURL'
export default function buildAdapter(adapter, { req, app }) {
  function getFromCache(cacheKey, req) {
    const result = memoryCache.get(cacheKey)
    if (result) {
      req.statistics.inc('API-CACHE-HIT', cacheKey)
      return result
    }
    req.statistics.inc('API-CACHE-MISS', cacheKey)
    return false
  }

  async function getFromNetwork(adapter, config, costKey, req) {
    const start = Date.now()
    const result = await adapter(config)
    req.statistics.set('API-COST', costKey, Date.now() - start)
    return result
  }

  const { memoryCache } = app
  return async function(config) {
    const { url, baseURL, method, params = {}, cache = false } = config
    if (method === 'get') {
      const cacheKey = buildURL(url, params).replace(baseURL, '')
      const costKey = cacheKey.replace(/(%[A-Z0-9]{2})+/g, '{text}').replace(/[\d,]+/g, '{id}')
      if (cache) {
        let result = getFromCache(cacheKey, req)
        if (!result) {
          result = getFromNetwork(adapter, config, costKey, req)
          memoryCache.set(cacheKey, result)
        }
        return result
      }
      return getFromNetwork(adapter, config, costKey, req)
    }
    return adapter(config)
  }
}
