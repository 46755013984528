import axios from 'axios'
import Vue from 'vue'
import Cookies from 'js-cookie'
import qs from 'qs'
import { encode64, getDeviceId, getRealIP, isRealExistence, parseCookie } from '~/assets/lib/tool'
import { getCookieExpires } from '~/assets/lib/dateFormat'
import buildAdapter from '~/server/axiosCacheAdapter'

const BASE_URL = `http://${ process.env.HOST || 'localhost' }:${ process.env.PORT || 3000 }`
const REQUEST_TIMEOUT = 15 * 1000
const X_CLIENT_ID = 'shop_web'
const DEFAULT_CITY_ID = 27 // 默认城市杭州

const { $toast: showMsg = () => {} } = Vue.prototype

// token签名
const tokenSign = token => token ? `Basic ${ encode64(token + ':') }` : ''

// 获取客户端城市信息
const getMyAppCity = async (realIP) => {
  const res = await axios({
    method: 'get',
    url: `${ BASE_URL }/proxy/my-app-city`,
    headers: { 'X-WZJ-Real-IP': realIP },
  })
  const { status, results = [{ id: DEFAULT_CITY_ID }] } = res.data || {}
  return status === 'SUCCESS' ? results[0].id : DEFAULT_CITY_ID
}

// 服务端axios配置
const serverAxiosConfig = async (context) => {
  const { req, res } = context
  const realIP = getRealIP(req)
  const cookies = parseCookie(req.headers.cookie)
  const token = cookies['token']
  const visitorIdFromCookie = cookies['wzj_visitor']
  const cityIdFromCookie = cookies['app-city-id']
  const visitorId = isRealExistence(visitorIdFromCookie) ? visitorIdFromCookie : getDeviceId()
  const cityId = isRealExistence(cityIdFromCookie) ? cityIdFromCookie : await getMyAppCity(realIP)

  res.setHeader('Set-Cookie', [
    `app-city-id=${ cityId }; path=/; expires=${ getCookieExpires(30) }`,
    `wzj_visitor=${ visitorId }; path=/; expires=${ getCookieExpires(365) }`
  ])

  return {
    baseURL: BASE_URL,
    timeout: REQUEST_TIMEOUT,
    headers: {
      'X-Client-Id': X_CLIENT_ID,
      'X-WZJ-Real-IP': realIP,
      'X-Request-Id': req.headers['x-request-id'] || '',
      'x-bdbs-device-id': visitorId,
      'app-city-id': cityId,
      'wzj-token': tokenSign(token)
    },
    adapter: buildAdapter(axios.defaults.adapter, context),
  }
}

// 客户端axios配置
const clientAxiosConfig = () => {
  const visitorId = Cookies.get('wzj_visitor') || ''
  const cityIdFromCookie = Cookies.get('app-city-id')
  const cityId = isRealExistence(cityIdFromCookie) ? cityIdFromCookie : DEFAULT_CITY_ID
  const token = Cookies.get('token')

  return {
    timeout: REQUEST_TIMEOUT,
    headers: {
      'X-Client-Id': X_CLIENT_ID,
      'x-bdbs-device-id': visitorId,
      'app-city-id': cityId,
      'wzj-token': tokenSign(token)
    },
  }
}

// 请求拦截器
const interceptorsRequest = reqConfig => {
  if (reqConfig.method === 'post' && !reqConfig.isFormData) {
    reqConfig.data = qs.stringify(reqConfig.data)
  }
  return reqConfig
}

// 响应拦截器
const interceptorsResponse = response => {
  const { status, data, config } = response
  if (status >= 400 && status < 500) {
    showMsg('页面出错啦~')
    return
  }
  if (status >= 500 && status < 600) {
    if (config.url.indexOf('items') > 0) {
      showMsg('获取不到该商品信息~')
      return
    }
    showMsg('服务器开小差啦！稍后试试~')
    return
  }
  if (data.status === 'FAILURE') {
    if (config.url.indexOf('signin') > 0) {
      return response.data
    }
    showMsg(data.error_message)
    return
  }
  if (data.status === 'TOKEN_ERROR') {
    showMsg('未登录，请登录')
    return
  }
  return response.data
}

// 拦截器异常处理
const interceptorsCatch = (msg = '服务器开小差啦！稍后试试~') => {
  showMsg(msg)
}

export default async function(context) {
  const config = process.server ? await serverAxiosConfig(context) : clientAxiosConfig()

  const instance = axios.create(config)

  instance.interceptors.request.use(interceptorsRequest, interceptorsCatch)

  instance.interceptors.response.use(interceptorsResponse, interceptorsCatch)

  context.axios = instance

  Vue.prototype.$http = instance
}
