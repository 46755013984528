// GrowingIO Analytics code version 2.1
// Copyright 2015-2017 GrowingIO, Inc. More info available at http://www.growingio.com

!(function(e, t, n, g, i) { e[i] = e[i] || function() { (e[i].q = e[i].q || []).push(arguments) }, n = t.createElement('script'), tag = t.getElementsByTagName('script')[0], n.async = 1, n.src = (document.location.protocol == 'https:' ? 'https://' : 'http://') + g, tag.parentNode.insertBefore(n, tag) }(window, document, 'script', 'assets.growingio.com/2.1/gio.js', 'gio'))

gio('init', 'a49dd7c1dcde2072', {})

// custom page code begin here

// custom page code end here

gio('send')
// End GrowingIO Analytics code version: 2.1
