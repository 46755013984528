import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _06291897 = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _b804af72 = () => interopDefault(import('../pages/app-links.vue' /* webpackChunkName: "pages/app-links" */))
const _72653de7 = () => interopDefault(import('../pages/collect/index.vue' /* webpackChunkName: "pages/collect/index" */))
const _345f5c13 = () => interopDefault(import('../pages/image-search/index.vue' /* webpackChunkName: "pages/image-search/index" */))
const _f89c91c8 = () => interopDefault(import('../pages/introduce/index.vue' /* webpackChunkName: "pages/introduce/index" */))
const _541e80f6 = () => interopDefault(import('../pages/oss-seo-page/index.vue' /* webpackChunkName: "pages/oss-seo-page/index" */))
const _87f8a73e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _17271a7d = () => interopDefault(import('../pages/upgradeHint.vue' /* webpackChunkName: "pages/upgradeHint" */))
const _5f6cd49c = () => interopDefault(import('../pages/homers/item.vue' /* webpackChunkName: "pages/homers/item" */))
const _a8079782 = () => interopDefault(import('../pages/relevance/item.vue' /* webpackChunkName: "pages/relevance/item" */))
const _0437af2d = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _4a855f25 = () => interopDefault(import('../pages/items/_id.vue' /* webpackChunkName: "pages/items/_id" */))
const _05c172e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7c24f674 = () => interopDefault(import('../pages/search' /* webpackChunkName: "" */))
const _58b81f9b = () => interopDefault(import('../pages/oss-seo-page' /* webpackChunkName: "" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/about-us",
      component: _06291897,
      name: "about-us"
    }, {
      path: "/app-links",
      component: _b804af72,
      name: "app-links"
    }, {
      path: "/collect",
      component: _72653de7,
      name: "collect"
    }, {
      path: "/image-search",
      component: _345f5c13,
      name: "image-search"
    }, {
      path: "/introduce",
      component: _f89c91c8,
      name: "introduce"
    }, {
      path: "/oss-seo-page",
      component: _541e80f6,
      name: "oss-seo-page"
    }, {
      path: "/search",
      component: _87f8a73e,
      name: "search"
    }, {
      path: "/upgradeHint",
      component: _17271a7d,
      name: "upgradeHint"
    }, {
      path: "/homers/item",
      component: _5f6cd49c,
      name: "homers-item"
    }, {
      path: "/relevance/item",
      component: _a8079782,
      name: "relevance-item"
    }, {
      path: "/brands/:id?",
      component: _0437af2d,
      name: "brands-id"
    }, {
      path: "/items/:id?",
      component: _4a855f25,
      name: "items-id"
    }, {
      path: "/",
      component: _05c172e8,
      name: "index"
    }, {
      path: "/categories/:name",
      component: _7c24f674,
      name: "categories"
    }, {
      path: "/styles/:id",
      component: _7c24f674,
      name: "styles"
    }, {
      path: "/spaces/:name/:category",
      component: _7c24f674,
      name: "spaces"
    }, {
      path: "/hot/:keyword",
      component: _7c24f674,
      name: "hot"
    }, {
      path: "/relevance/:id",
      component: _a8079782,
      name: "relevance"
    }, {
      path: "/homers/:id",
      component: _5f6cd49c,
      name: "homer"
    }, {
      path: "/homers",
      component: _58b81f9b,
      name: "homer-index"
    }, {
      path: "/articles",
      component: _58b81f9b,
      name: "articles"
    }, {
      path: "/articles/:id",
      component: _58b81f9b,
      name: "article-page"
    }],

    fallback: false
  })
}
