
import Vue from 'vue'
import formatTime from '~/assets/lib/dateFormat'
// import breadcrumb from '~/components/UI/breadcrumb'
import imgLoader from '~/assets/lib/imageFormat'
import swiper from '~/components/UI/swiper'
import swiperItem from '~/components/UI/swiperItem'
// import dialog from '~/components/UI/dialog'

Vue.filter('imageFormat', (value, size) => imgLoader(value, size))
Vue.filter('dateFormat', value => formatTime(value))
Vue.filter('dateTimeFormat', value => formatTime(value, 'YYYY.MM.DD hh:mm:ss'))

// Vue.use(breadcrumb)
Vue.use(swiper)
Vue.use(swiperItem)
// Vue.use(dialog)
