import loadScript from '~/assets/lib/loadScript'

const api = process.env.API_URL ? process.env.API_URL.replace(/api/, 'm') : 'https://m.wzj.com'

loadScript('//s.wzj.com/h5-static/js/bj-report-tryjs.min.js', () => {
  BJ_REPORT.init({
    id: process.env.NODE_ENV === 'development' ? 0 : 1, // 上报id,不指定id将不上报 开发环境不上报，生产环境上报
    url: `${api}/js-error-analysis`, // 指定上报地址
    combo: 0, // combo是否合并上报，0关闭，1启动（默认）
    ignore: [/Script error/i, /WeixinJSBridge/i, /defineProperty/i, /HTMLDocument/i, /TouTiao/i],
    repeat: 1, // 对于同一个错误只上报一次
    ext: {
      group: 'shop_web',
    },
  }).tryJs().spySystem() // spySystem检查跨域中定时器触发的错误
})
