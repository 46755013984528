import tpl from './Confirm.vue'
const plugin = {}

plugin.install = function(Vue) {
  let $vm = null
  const ChildExtend = Vue.extend(tpl)
  if (!$vm) {
    $vm = new ChildExtend({
      el: document.createElement('div'),
    })
    document.body.appendChild($vm.$el)
  }

  const confirm = {
    show(option = {}) {
      $vm = Object.assign($vm, option, { isVisible: true })
    },
    hide() {
      $vm.isVisible = false
    },
  }

  Vue.prototype.$confirm = confirm
}

export default plugin

