import { getInitDataWZJ, getDataType } from '~/assets/lib/tool'
import Cookies from 'js-cookie'

const IMAGE_STYLES = [
  { 'style_name': 'w100-webp', 'width': '100', 'is_webp': true },
  { 'style_name': 'w200-webp', 'width': '200', 'is_webp': true },
  { 'style_name': 'w300-webp', 'width': '300', 'is_webp': true },
  { 'style_name': 'w400-webp', 'width': '400', 'is_webp': true },
  { 'style_name': 'w500-webp', 'width': '500', 'is_webp': true },
  { 'style_name': 'w600-webp', 'width': '600', 'is_webp': true },
  { 'style_name': 'w700-webp', 'width': '700', 'is_webp': true },
  { 'style_name': 'w800-webp', 'width': '800', 'is_webp': true },
  { 'style_name': 'w1000-webp', 'width': '1000', 'is_webp': true },
  { 'style_name': 'w1600-webp', 'width': '1600', 'is_webp': true },
  { 'style_name': 'w100', 'width': '100', 'is_webp': false },
  { 'style_name': 'w200', 'width': '200', 'is_webp': false },
  { 'style_name': 'w300', 'width': '300', 'is_webp': false },
  { 'style_name': 'w400', 'width': '400', 'is_webp': false },
  { 'style_name': 'w500', 'width': '500', 'is_webp': false },
  { 'style_name': 'w600', 'width': '600', 'is_webp': false },
  { 'style_name': 'w700', 'width': '700', 'is_webp': false },
  { 'style_name': 'w800', 'width': '800', 'is_webp': false },
  { 'style_name': 'w1000', 'width': '1000', 'is_webp': false },
  { 'style_name': 'w1600', 'width': '1600', 'is_webp': false },
  { 'style_name': 'w120-webp', 'width': '120', 'is_webp': true },
  { 'style_name': 'w150-webp', 'width': '150', 'is_webp': true },
  { 'style_name': 'w247-webp', 'width': '247', 'is_webp': true },
  { 'style_name': 'w450-webp', 'width': '450', 'is_webp': true },
  { 'style_name': 'w480-webp', 'width': '480', 'is_webp': true },
  { 'style_name': 'w795-webp', 'width': '795', 'is_webp': true },
]

// 设备是否支持webp格式
export function hasWebpSupport() {
  const canvas = document.createElement('canvas')
  if (canvas && typeof canvas.toDataURL === 'function') {
    const webp = canvas.toDataURL('image/webp')
    return webp ? webp.indexOf('data:image/webp') === 0 : false
  }
  return false
}

// webp图片加载器, 优先使用webp格式图片
export function webpLoader(support, url, size = 800) {
  let imageStyles = IMAGE_STYLES
  if (process.client) {
    const cacheImageStyles = getInitDataWZJ('initDataWZJ_1.1', 'appConfigData', 'image_styles')
    imageStyles = getDataType(cacheImageStyles) === '[object Array]' ? cacheImageStyles : IMAGE_STYLES
  }

  // 找到距离目标值size最近的可用尺寸格式
  const styles = imageStyles.filter(item => item.is_webp === support).sort((a, b) => Math.abs(a.width - size) - Math.abs(b.width - size))[0]
  if (styles && styles.style_name) {
    return `${url}?x-oss-process=style/${styles.style_name}`
  } else {
    return `${url}?x-oss-process=style/w${size}${support ? '-webp' : ''}`
  }
}

// 图片加载器
export default function imgLoader(url, size) {
  let hasWebpSupportCache = false
  let hasWebpSupportFlag
  if (process.client) {
    hasWebpSupportCache = Cookies.get('has_webp_support') === 'true'
    hasWebpSupportFlag = hasWebpSupport()
    Cookies.set('has_webp_support', hasWebpSupportFlag, {
      domain: '',
      expires: 30,
      path: '/',
    })
  }
  // 不是wzj域直接返回
  if (!url || url.indexOf('wzj.com') === -1 || /\?x-oss-process/i.test(url) || url.indexOf('//s.wzj.com') > 0) {
    return url
  } else if (hasWebpSupportCache) {
    return webpLoader(true, url, size)
  } else if (hasWebpSupportFlag) {
    return webpLoader(true, url, size)
  } else {
    return webpLoader(false, url, size)
  }
}
