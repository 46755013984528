import Vue from 'vue'
import axios from 'axios'

const api = process.env.API_URL ? process.env.API_URL.replace(/api/, 'm') : 'https://m.wzj.com'
// 生产环境环境，捕获vue内部错误进行手动上报
Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'development') {
    const { message: msg, stack } = err
    axios.get(`${api}/js-error-analysis`, {
      params: {
        stack: stack.substr(0, 500), // 异常堆栈信息，避免错误信息过长
        msg, // 异常信息
        ext: JSON.stringify({
          group: 'shop_web', // 钉钉群
        }),
        from: window.location.href,
      },
    }).catch(e => console.log(e))
  } else {
    console.log(err)
  }
}
