import Vue from 'vue'
import 'lazysizes'
import BaiduMap from 'vue-baidu-map'
import VueQrCode from '@chenfengyuan/vue-qrcode'
import WzjUI from '@wzj/wzj-ui'
import '@wzj/wzj-ui/dist/main.css'
import confirm from '~/components/UI/confirm'
import photoSwiper from '~/components/UI/photoSwiper'
// import Toast from '~/components/UI/toast'
Vue.use(photoSwiper)
Vue.use(confirm)
Vue.use(WzjUI.Dialog)
// Vue.use(WzjUI.Message)
Vue.use(WzjUI.Breadcrumb)
Vue.use(WzjUI.Captcha)
Vue.use(WzjUI.Toast)
// Vue.use(Toast)
Vue.use(BaiduMap, {
  ak: 'suc1TltyCOYQFveTmdmOIuIa',
})
Vue.component('vue-qr', VueQrCode)