/*eslint-disable*/
import Cookies from "js-cookie";
import axios from 'axios'

const api = process.env.API_URL ? process.env.API_URL.replace(/api/, 'm') : 'https://m.wzj.com'

let userAgent, cacheInitUrl
if (process.client) {
  userAgent = window.navigator.userAgent
  cacheInitUrl = window.location.href
}
const TABLE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

export function isHash() {
  return window.location.hash.substr(0, 2) === '#/'
}

export function getLocalStorage(key) {
  let r;
  try {
    if (window.localStorage) {
      r = JSON.parse(window.localStorage.getItem(key));
    }
  } catch (e) {
    return r;
  }
  return r;
}

export function setLocalStorage(key, value) {
  try {
    let str = ''
    str = JSON.stringify(value)
    if (window.localStorage) {
      window.localStorage.setItem(key, str)
    }
  } catch(e) {
    console.log(e)
  }
}

export function delLocalStorage(key) {
  if (window.localStorage) {
    window.localStorage.removeItem(key)
  }
}

export function clearLocalStorage() {
  if (window.localStorage) {
    window.localStorage.clear()
  }
}

export function updateLocalStorage(type, key, val) {
  const obj = getLocalStorage(type) ? getLocalStorage(type) : false
  if (obj) {
    for (const k in obj) {
      if (k === key) {
        if (val === 'delete') {
          delete obj[k]
          setLocalStorage(type, obj)
          break
        } else {
          obj[key] = val
        }
      } else {
        obj[val.proId] = val
      }
      setLocalStorage(type, obj)
    }
  } else {
    const temp = {}
    temp[key] = val
    setLocalStorage(type, temp)
  }
}

export function getSessionStorage(key) {
  let r
  try {
    if (window.sessionStorage) {
      r = JSON.parse(window.sessionStorage.getItem(key))
    }
  } catch (e) {
    return r
  }
  return r
}

// safari 存在sessionStorage配额限制问题
export function setSessionStorage(key, value) {
  try {
    let str = ''
    str = JSON.stringify(value)
    if (window.sessionStorage) {
      window.sessionStorage.setItem(key, str)
    }
  } catch (e){
    console.log(e)
  }
}

export function delSessionStorage(key) {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem(key)
  }
}

export function clearSessionStorage() {
  if (window.sessionStorage) {
    window.sessionStorage.clear()
  }
  if (window.localStorage) {
    window.localStorage.clear()
  }
}

export function updateSessionStorage(type, key, val) {
  const obj = getSessionStorage(type) ? getSessionStorage(type) : false
  if (obj) {
    /*eslint-disable*/
    for (const k in obj) {
      if (k === key) {
        if (val === 'delete') {
          delete obj[k]
          setSessionStorage(type, obj)
          break
        } else {
          obj[key] = val
        }
      } else {
        obj[val.proId] = val
      }
      setSessionStorage(type, obj)
    }
  } else {
    const temp = {}
    temp[key] = val
    setSessionStorage(type, temp)
  }
}

export function clearAllStorage() {
  if (window.sessionStorage) {
    window.sessionStorage.clear()
  }
}

/**
 * base64 encode
 * @param input
 * @returns {string}
 */
export function encode64(input) {
  input = String(input)
  if (/[^\0-\xFF]/.test(input)) {
    // Note: no need to special-case astral symbols here, as surrogates are
    // matched, and the input is supposed to only contain ASCII anyway.
    console.error(
      'The string to be encoded contains characters outside of the ' +
      'Latin1 range.'
    )
  }
  var padding = input.length % 3
  var output = ''
  var position = -1
  var a
  var b
  var c
  var buffer
  // Make sure any padding is handled outside of the loop.
  var length = input.length - padding

  while (++position < length) {
    // Read three bytes, i.e. 24 bits.
    a = input.charCodeAt(position) << 16
    b = input.charCodeAt(++position) << 8
    c = input.charCodeAt(++position)
    buffer = a + b + c
    // Turn the 24 bits into four chunks of 6 bits each, and append the
    // matching character for each of them to the output.
    output += (
      TABLE.charAt(buffer >> 18 & 0x3F) +
      TABLE.charAt(buffer >> 12 & 0x3F) +
      TABLE.charAt(buffer >> 6 & 0x3F) +
      TABLE.charAt(buffer & 0x3F)
    )
  }

  if (padding == 2) {
    a = input.charCodeAt(position) << 8
    b = input.charCodeAt(++position)
    buffer = a + b
    output += (
      TABLE.charAt(buffer >> 10) +
      TABLE.charAt((buffer >> 4) & 0x3F) +
      TABLE.charAt((buffer << 2) & 0x3F) +
      '='
    )
  } else if (padding == 1) {
    buffer = input.charCodeAt(position)
    output += (
      TABLE.charAt(buffer >> 2) +
      TABLE.charAt((buffer << 4) & 0x3F) +
      '=='
    )
  }
  return output
}
/**
 * base64 decode
 * @param input
 */
export function decode64 (input) {
  input = String(input)
    .replace(REGEX_SPACE_CHARACTERS, '')
  var length = input.length
  if (length % 4 == 0) {
    input = input.replace(/==?$/, '')
    length = input.length
  }
  if (
    length % 4 == 1 ||
    // http://whatwg.org/C#alphanumeric-ascii-characters
    /[^+a-zA-Z0-9/]/.test(input)
  ) {
    console.error(
      'Invalid character: the string to be decoded is not correctly encoded.'
    )
  }
  var bitCounter = 0
  var bitStorage
  var buffer
  var output = ''
  var position = -1
  while (++position < length) {
    buffer = TABLE.indexOf(input.charAt(position))
    bitStorage = bitCounter % 4 ? bitStorage * 64 + buffer : buffer
    // Unless this is the first of a group of 4 characters…
    if (bitCounter++ % 4) {
      // …convert the first 8 bits to a single ASCII character.
      output += String.fromCharCode(
        0xFF & bitStorage >> (-2 * bitCounter & 6)
      )
    }
  }
  return output
}

// debounce 时间间隔 t 内若再次触发事件，则重新计时，直到停止时间大于或等于 t 才执行函数。
// debounce(function (event) {console.log('the Ajax request')}, 250))
export function debounce(fn, delay) {
  let timer = null
  return function () {
    let context = this, args = arguments
    window.clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

// 对象数组排序
export function keySort(propertyName, type) {
  return function (object1, object2) {
    let value1 = object1[propertyName]
    let value2 = object2[propertyName]
    if (value2 < value1) {
      return type === true ? -1 : 1
    } else if (value2 > value1) {
      return type === true ? 1 : -1
    } else {
      return 0
    }
  }
}

// 数组交换元素
export function exchangeArray(arr, index1, index2) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0]
  return arr
}

// 数组删除指定元素
export function removeByValue(arr, val) {
  let temp = []
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== val) {
      temp.push(arr[i])
    }
  }
  return temp
}

// 千分位格式化
export function formatMoney(s, n) {
  n = n > 0 && n <= 20 ? n : 2
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + ""
  let l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1]
  let t = ""
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "")
  }
  if (r === '00' || r === '0') {
    return t.split("").reverse().join("")
  } else {
    return t.split("").reverse().join("") + "." + r
  }
}

// 对象值是否全等
export function isEqual(a, b) {
  try {
    return JSON.stringify(a) === JSON.stringify(b)
  } catch (e) {
    return null
  }
}

// 奇淫巧技,深拷贝对象，弊端 对象的函数属性会被过滤掉
export function objCopy(a) {
  try {
    return JSON.parse(JSON.stringify(a))
  } catch (e) {
    return null
  }
}

// 奇淫巧技,深拷贝数组
export function arrayCopy(a) {
  if (a instanceof Array) {
    return [...a]
  }
  return null
}

// 将图片的base64转换为blob对象
export function dataURItoBlob(base64Data) {
  let byteString = ''
  if(base64Data.split(',')[0].indexOf('base64') >= 0){
    byteString = atob(base64Data.split(',')[1]);
  } else {
    byteString = unescape(base64Data.split(',')[1]);
  }

  const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for(var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], {
    type: mimeString
  });
}

/**
 * 类型判断
 * @param data
 * @returns {*}
 * [object Number] [object String] [object Undefined] [object Boolean] [object Object] [object Array] [object Function]
 */
export function getDataType(data) {
  return Object.prototype.toString.call(data)
}

export const isArray = Array.isArray

export function isString(val) {
  return typeof val === 'string'
}

export function isBoolean(val) {
  return val === true || val === false
}

export function isFunction(val) {
  return typeof val === 'function'
}

export function isObject(obj) {
  return obj !== null && typeof obj === 'object'
}

export function isPlainObject(obj) {
  return isObject(obj) && Object.getPrototypeOf(obj) == Object.prototype
}

export function isBlob(obj) {
  return typeof Blob !== 'undefined' && obj instanceof Blob
}

export function isFormData(obj) {
  return typeof FormData !== 'undefined' && obj instanceof FormData
}

export function getQueryString(name, locationHref = window.location.href) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
  let r = locationHref.split('?')[1] ? locationHref.split('?')[1].match(reg) : ''
  if (r !== null && r !== '') {
    return decodeURI(r[2])
  }
  return null
}

export function getInitDataWZJ(storageName, key1, key2) {
  const initData = getLocalStorage(storageName)
  if (key1 && initData && initData[key1]) {
    if (key2 && initData[key1][key2]) {
      return initData[key1][key2]
    } else {
      return initData[key1]
    }
  } else {
    return false
  }
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function parseCookie(cookies) {
  const pattern = /([^=]+)=([^;]+);?\s*/g
  let result
  let value = {}
  while((result = pattern.exec(cookies)) != null) {
    value[result[1]] = result[2]
  }
  return value
}

//获取客户端真实ip
export function getRealIP(req) {
  let ip = req.headers['x-forwarded-for'] ||
    req.ip ||
    req.connection.remoteAddress ||
    req.socket.remoteAddress ||
    req.connection.socket.remoteAddress || ''
  if(ip.split(',').length > 0){
    ip = ip.split(',')[0]
  }
  return ip
}

export function isMobile(value) {
  return /^1\d{10}$/.test(value)
}


// 单个 dom 查询 domSeek('.a')
export function domSeek(target) {
  if (!target) {
    return Promise.reject(`${target} target non-existent`);
  }
  const dom = document.querySelector(target);
  if (!dom) {
    return Promise.reject(`${target} dom non-existent`);
  }
  return Promise.resolve(dom);
}

// 获取设备ID
export function getDeviceId() {
  const deviceId = Cookies.get('wzj_visitor');
  return isRealExistence(deviceId) ? deviceId : uuid()
}

export function isRealExistence(str) {
  return str && str !== '' && str !== 'undefined'
}

// 埋点接口地址
export function getImgBeacon(host = window.location.host) {
  return /(wozaijia|wzj)/i.test(host) ? host.replace(/www/i, 't') : 'test-t.wzj.com';
}

// 获取埋点配置
export function getStatisticsConfig(deviceId, imgBeacon = getImgBeacon()) {
  return {
    imgBeacon: `//${imgBeacon}/api/1.2/pushTrace.gif`,
    beaconField: 'traceInfoJson',
    deferSendData: false, // 值为 all(全部) ck(点击), ev(曝光)
    isDisable: !imgBeacon, // 是否禁用统计
    deviceId, // 设备唯一ID
    extendParams: {    // 传入公共的参数
      project_name: 'wozaijia',
      type: 1,
      account_name: 'wozaijia_pc',
      catalog: 'pc_mall',
    },
    clickCommonParams: {
      event_name: 'click_event',
    },
    exposureCommonParams: {
      event_name: 'exposure_event',
    },
    pageVisitCommonParams: {
      event_name: 'page_visit',
    },
    pageTimeCallback: stayObj => ({ // 页面停留时间的回调，用来确定传参， stayPageTime为true时必填
      url: stayObj.pagePath,
      event_name: 'page_visit_time',
      option1: stayObj.stayTime,
      option2: 'burning_times',
    }),
    // pv的callback
    pagePvCallback(fullPath) {
      if (!fullPath || (fullPath && fullPath.indexOf('referrer_page') === -1)) {
        return '';
      }
      const pathList = fullPath.split(/\?|&/ig);
      let target = pathList.filter(pt => pt.indexOf('referrer_page') > -1);
      if (!target || !target[0]) {
        return '';
      }
      target = target[0].split('=')[1] || '';
      return {
        refererPage: target,
      };
    },
    sendDataCallback() {
      return {
        token: Cookies.get('token') || '',
      };
    },
  };
}

export function fromMobile(userAgent) {
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|cfnetwork|darwin/i.test(userAgent))
}

export function fromRobot(userAgent) {
  return (/googlebot|yodaobot|bingbot|spider/i.test(userAgent))
}

export function normalizeObjectEnNameForUrl(objectName) {
  try {
    return encodeURIComponent(objectName.toLowerCase().trim().replace(/[\s|&./]+/g, '-'))
  } catch (e) {
    errorHandler(e, { objectName })
  }
}

export function normalizeObjectCnNameForUrl(objectName) {
  return encodeURIComponent(normalizeObjectCnName(objectName))
}

export function normalizeObjectCnName(objectName) {
  // 搜索词中各种连接符统一替换为一个空格
  return objectName.toLowerCase().trim().replace(/[\s|&./]+/g, ' ')
}

export function buildSubpageSearchQuery({ results = [] }, query, { name, params }) {
  const searchQuery = { search_page_type: 'classification' }
  const { categories = [], spaces = [], styles = [] } = results[0]
  switch (name) {
    case 'categories':
      const category = categories.find(cat => normalizeObjectEnNameForUrl(cat.en_name) === params.name.toLowerCase())
      if (!category) return null
      searchQuery.keyword = category.name
      searchQuery.category_id = category.id
      searchQuery.type = 'category'
      break
    case 'styles':
      const style = styles.find(s => s.id === params.id)
      if (!style) return null
      searchQuery.keyword = style.name
      searchQuery.style_id = style.id
      searchQuery.type = 'style'
      break
    case 'spaces':
      const space = spaces.find(s => normalizeObjectEnNameForUrl(s.en_name) === params.name.toLowerCase())
      if (!space) return null
      const spaceCategory = space.categories.find(cat => normalizeObjectEnNameForUrl(cat.en_name) === params.category.toLowerCase())
      if (!spaceCategory) return null
      searchQuery.keyword = spaceCategory.name
      searchQuery.category_id = spaceCategory.id
      searchQuery.type = 'category'
      break
    default:
      return null
  }
  return Object.assign({}, searchQuery, query)
}

export function hotToSubpage({ results = [] }, keyword) {
  const { categories = [], spaces = [], styles = [] } = results[0]
  const catLinks = categories.filter(cat => normalizeObjectCnName(cat.name) === keyword).map(cat => `/categories/${normalizeObjectEnNameForUrl(cat.en_name)}`)
  if(catLinks && catLinks.length>0 ){
    return catLinks[0]
  }
  const spaceLinks = spaces.filter(space => normalizeObjectCnName(space.name) === keyword).map(space => `/spaces/${normalizeObjectEnNameForUrl(space.en_name)}/all`)
  if(spaceLinks && spaceLinks.length>0 ){
    return spaceLinks[0]
  }
  const styleLinks = styles.filter(style => normalizeObjectCnName(style.name) === keyword).map(style => `/styles/${normalizeObjectEnNameForUrl(style.id)}`)
  if(styleLinks && styleLinks.length>0 ){
    return styleLinks[0]
  }
  return null
}

export function trimCityTail(cityName) {
  return cityName.trim().replace(/^(.{2,})市$/, '$1')
}

export function isFurniture(name) {
  // 临时方案，服务端商品详情改造中，未来直接使用字段
  return !(/床上|床品|床垫/.test(name)) && /沙发|柜|桌|椅|凳|几|床|柜|架/.test(name)
}

export function styleCategorySeoLinks(style) {
  return ['沙发', '电视柜', '餐桌', '双人床'].map(category => ({
    text: `更多${style}${category}热销中`,
    link: `/hot/${normalizeObjectCnNameForUrl(style + category)}`,
  }))
}

export function getIEVersion(userAgent) {
  return parseInt(userAgent.replace(/^.*msie ([\d.]+).*$/i,'$1').replace(/^.*rv:([\d.]+).*$/i,'$1')) || false
}

export function errorHandler (err, info = {}, from = '') {
  if (process.env.NODE_ENV !== 'development') {
    const { message: msg, stack } = err
    axios.get(`${api}/js-error-analysis`, {
      params: {
        stack: stack.substr(0, 500), // 异常堆栈信息，避免错误信息过长
        msg, // 异常信息
        ext: JSON.stringify({
          info,
          group: 'shop_web', // 钉钉群
        }),
        from,
      },
    }).catch(e => console.log(e))
  } else {
    console.log(err)
  }
}

export function getLocationOrigin() {
  const { origin, protocol, port, hostname } = window.location
  if (!origin) {
    return `${protocol}//${hostname}${port ? ':' + port : ''}`
  }
  return origin
}

export default isHash
