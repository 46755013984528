import Vue from 'vue'
import Cookies from 'js-cookie'
import { uuid, parseCookie, getRealIP, getLocalStorage, setLocalStorage, getDeviceId } from '~/assets/lib/tool'

// 埋点接口地址
function getImgBeacon(host = window.location.host) {
  return /(wozaijia|wzj)/i.test(host) ? host.replace(/www/i, 't') : 'test-t.wzj.com'
}
// 获取埋点配置
function getConfig(deviceId, imgBeacon = getImgBeacon()) {
  return {
    imgBeacon: `//${imgBeacon}/api/1.2/pushTrace.gif`,
    beaconField: 'traceInfoJson',
    deferSendData: false, // 值为 all(全部) ck(点击), ev(曝光)
    isDisable: !imgBeacon, // 是否禁用统计
    deviceId, // 设备唯一ID
    extendParams: { // 传入公共的参数
      project_name: 'wozaijia',
      type: 1,
      account_name: 'wozaijia_pc',
      catalog: 'pc_mall',
    },
    clickCommonParams: {
      event_name: 'click_event',
    },
    exposureCommonParams: {
      event_name: 'exposure_event',
    },
    pageVisitCommonParams: {
      event_name: 'page_visit',
    },
    pageTimeCallback: stayObj => ({ // 页面停留时间的回调，用来确定传参， stayPageTime为true时必填
      url: stayObj.pagePath,
      event_name: 'page_visit_time',
      option1: stayObj.stayTime,
      option2: 'burning_times',
    }),
    // pv的callback
    pagePvCallback(fullPath) {
      if (!fullPath || (fullPath && fullPath.indexOf('referrer_page') === -1)) {
        return document.referrer
      }
      const pathList = fullPath.split(/\?|&/ig)
      let target = pathList.filter(pt => pt.indexOf('referrer_page') > -1)
      if (!target || !target[0]) {
        return document.referrer
      }
      target = target[0].split('=')[1] || document.referrer
      return {
        refererPage: target,
      }
    },
    sendDataCallback() {
      return {
        token: Cookies.get('token') || '',
      }
    },
  }
}
if(process.env.NODE_ENV !== 'development') {
  const { WZJStatistics } = window
  const config = getConfig(getDeviceId())
  Vue.prototype.$statistics = new WZJStatistics(config)
}

