
/**
 *
 * @param src js文件的路径 String
 * @param callback 加载文件成功后的回调
 * loadScript('http://s.wzj.com/h5-static/css/exif.min.js',function(){
 *  console.log('onload');
 * });
 */

const loadScript = (src, callback) => {
  const script = document.createElement('script')
  const head = document.getElementsByTagName('head')[0]
  script.type = 'text/javascript'
  script.charset = 'UTF-8'
  script.src = src

  if (script.addEventListener) {
    script.addEventListener('load', () => {
      if (callback) {
        callback()
      }
    }, false)
  } else if (script.attachEvent) {
    script.attachEvent('onreadystatechange', () => {
      const target = window.event.srcElement
      if (target.readyState === 'loaded') {
        if (callback) {
          callback()
        }
      }
    })
  }

  head.appendChild(script)
}

export default loadScript
